import BaseSelect from '@/components/common/base-select/base-select.vue'

export const fromConfig = (content) => {
  return [
    {
      label: '供应商名称',
      prop: 'enterpriseId',
      attrs: {
        placeholder: '请选择',
        options: content.dropOptions,
        selectedField: ['keyId', 'enterpriseName'],
        filterable: true
      },
      tag: BaseSelect
    },
    {
      label: '锁定状态',
      prop: 'lockStatus',
      attrs: {
        placeholder: '请选择',
        options: content.lockStatusDict,
        selectedField: ['dictId', 'dictName'],
        filterable: true
      },
      tag: BaseSelect
    }
  ]
}

export const columnsConfig = (content) => {
  return [
    {
      type: 'selection',
      prop: 'selection',
      selectable: function (row) {
        return row.lockStatus === '1'
      }
    },
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '供应商',
      prop: 'enterpriseName',
      minWidth: '150px'
    },
    {
      label: '统一社会信用代码',
      prop: 'creditCode',
      minWidth: '250px'
    },
    {
      label: '回款锁定状态',
      prop: 'lockStatus',
      minWidth: '150px'
    },
    {
      label: '操作',
      prop: 'action',
      width: '165px',
      fixed: 'right'
    }
  ]
}
