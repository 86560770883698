<template>
  <div>
    <!--表单-->
    <BaseForm
      :componentList="from"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      ref="fromdata"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
    ></BaseForm>
    <div class="main-page-table__header">
      <base-button
        label="批量解锁"
        @click="batchUnlock"
      ></base-button>
    </div>
    <!--表格-->
    <Basetable
      :columns="columns"
      :api="api"
      :getApi="'getPage'"
      :showPage="true"
      :loadCount="loadCount"
      :queryParas="queryParas"
      :selectedRows.sync="selectedRows"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      ref="tableData"
      :dataSource.sync="tableData"
      :webPage="false"
      class="main-page-table with-add"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="lockStatus" slot-scope="scope" >
        {{ scope.row.lockStatus === '0' ? '未锁定' : '已锁定' }}
      </template>
      <template slot="action" slot-scope="scope">
        <IconButton
          v-if="scope.row.lockStatus === '1'"
          @click.prevent="handleLock('0', scope.row.keyId)"
          content="解锁"
          icon="iconfont iconjiesuo"
        ></IconButton>
        <IconButton
          v-if="scope.row.lockStatus === '0'"
          @click.prevent="handleLock('1', scope.row.keyId)"
          content="加锁"
          icon="iconfont iconjiasuo"
        ></IconButton>
      </template>
    </Basetable>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form/base-form'
import Basetable from '@/components/common/table/base-table/base-table'
import { fromConfig, columnsConfig } from './utils/config.js'
import { supplierApi } from '@/api/companyApi'
import store from '@/store'
export default {
  name: 'supplierManagement',
  components: {
    BaseForm,
    Basetable,
    IconButton,
    BaseButton
  },
  data () {
    return {
      dropOptions: [],
      selectedRows: [],
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        enterpriseLabel: 1
      },
      loadCount: 0,
      tableData: []
    }
  },
  created () {
    // 申请主体下拉
    supplierApi.drop().then((res) => {
      this.dropOptions = res.data
    })
  },
  mounted () {
    this.handleFilter()
  },
  computed: {
    columns () {
      return columnsConfig(this)
    },
    from () {
      return fromConfig(this)
    },
    api () {
      return supplierApi
    },
    lockStatusDict () {
      return store.state.sys.dictMaps.ACCOUNT_LOCK_STATUS
    }
  },
  methods: {
    /**
     * 查询
     */
    handleFilter () {
      this.loadCount++
    },
    /**
     * 重置
     */
    clearParams () {
      this.queryParas = this.$options.data().queryParas
    },
    // 批量解锁
    batchUnlock () {
      this.handleLock('0', this.selectedRows.map(item => item.keyId))
    },
    // 加/解锁
    handleLock (lockStatus, enterpriseIds) {
      const tipMessage = lockStatus === '1' ? '回款锁定' : '回款解锁'
      this.$confirm(`是否对供应商进行${tipMessage}操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async res => {
        const params = {
          lockStatus,
          enterpriseIds: Array.isArray(enterpriseIds) ? enterpriseIds : [enterpriseIds]
        }
        try {
          const { data } = await supplierApi.updateSupplierLock(params)
          data ? this.success(`${tipMessage}成功`) : this.error(`${tipMessage}失败`)
          this.handleFilter()
        } catch (error) {
          this.handleFilter()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.pd15 {
  padding: 15px;
}
</style>
<style lang="scss">
.more-dropdown{
  .el-dropdown-menu__item{
    height: 40px;
    line-height: 40px !important;
    // color: #FFFFFF !important;
    &:hover{
      background-color: #4A7CF0 !important;
      color: #FFFFFF;
    }
  }
}
.iconfont {
  &.el-icon-more{
    font-family: element-icons !important;
  }
}
</style>
